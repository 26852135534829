import { Refine, Authenticated } from "@refinedev/core";
import {
  useNotificationProvider,
  ThemedLayoutV2,
  ErrorComponent,
  RefineThemes,
} from "@refinedev/antd";
import dataProvider from "@refinedev/simple-rest";
import routerProvider, {
  NavigateToResource,
  CatchAllNavigate,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { ConfigProvider } from "antd";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  ApartmentOutlined,
  BuildOutlined,
  ClusterOutlined,
  ContactsOutlined,
  ContainerOutlined,
  CopyOutlined,
  DashboardOutlined,
  DeploymentUnitOutlined,
  DingdingOutlined,
  DollarOutlined,
  GroupOutlined,
  HomeOutlined,
  IdcardOutlined,
  InsertRowLeftOutlined,
  MoneyCollectOutlined,
  PartitionOutlined,
  PhoneOutlined,
  PicRightOutlined,
  ReconciliationOutlined,
  ScheduleOutlined,
  ShoppingCartOutlined,
  TransactionOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import "@refinedev/antd/dist/reset.css";
import axios, { AxiosRequestConfig } from "axios";

import authProvider from "authProvider";
import { ColorModeContextProvider } from "contexts/color-mode";
import { API_URL } from "api";

import { Login } from "pages/login";
import { Register } from "pages/register";
import { ForgotPassword } from "pages/forgot-password";
import { Dashboard } from "pages/dashboard";
import { CustomSider, Header } from "components";
import { UserList, UserEdit, UserCreate, UserShow } from "pages/users";
import {
  ApartmentsCreate,
  ApartmentsList,
  ApartmentsEdit,
  ApartmentsShow,
} from "pages/cart-management/apartments";
import {
  BuildingsCreate,
  BuildingsList,
  BuildingsEdit,
  BuildingsShow,
} from "pages/cart-management/buildings";
import {
  BuildingsApartmentsEdit,
  BuildingsApartmentsList,
} from "pages/cart-management/buildings/buildings-apartments";
import {
  AppointmentsList,
  AppointmentsCreate,
  AppointmentsEdit,
  AppointmentsShow,
} from "pages/customer-management/appointments";
import {
  LandlordsList,
  LandlordsCreate,
  LandlordsEdit,
  LandlordsShow,
} from "pages/cart-management/landlords";
import {
  CustomersList,
  CustomersCreate,
  CustomersEdit,
  CustomersShow,
} from "pages/customer-management/customers";
import {
  ContractsList,
  ContractsCreate,
  ContractsEdit,
  ContractsShow,
} from "pages/payment/contracts";
import {
  BuildingGroupsList,
  BuildingGroupsCreate,
  BuildingGroupsEdit,
  BuildingGroupsShow,
} from "pages/cart-management/sale-rights-management/building-groups";
import {
  SaleGroupsList,
  SaleGroupsCreate,
  SaleGroupsEdit,
  SaleGroupsShow,
} from "pages/cart-management/sale-rights-management/sale-groups";
import {
  SaleRightsList,
  SaleRightsCreate,
  SaleRightsEdit,
  SaleRightsShow,
} from "pages/cart-management/sale-rights-management/sale-rights";
import {
  BillsCreate,
  BillsEdit,
  BillsShow,
  BillsList,
} from "pages/financial-management/bills";
import {
  TransactionEdit,
  TransactionList,
  TransactionShow,
} from "pages/financial-management/transactions";
import {
  CommissionEdit,
  CommissionList,
  CommissionShow,
} from "pages/financial-management/commissions";
import {
  DealsEdit,
  DealsList,
  DealsShow,
} from "pages/financial-management/deal";
import {
  AreasCreate,
  AreasEdit,
  AreasList,
  AreasShow,
} from "pages/cart-management/areas";
import { TeamsCreate, TeamsEdit, TeamsList, TeamsShow } from "pages/teams";

// only role team-leader
import {
  TeamsManagementCreate,
  TeamsManagementEdit,
  TeamsManagementList,
  TeamsManagementShow,
} from "pages/team-management/teams";
import {
  DealsTeamEdit,
  DealsTeamList,
  DealsTeamShow,
} from "pages/team-management/deal";
import {
  AppointmentsTeamCreate,
  AppointmentsTeamEdit,
  AppointmentsTeamList,
  AppointmentsTeamShow,
} from "pages/team-management/appointments";
import {
  RoomsCreate,
  RoomsEdit,
  RoomsList,
  RoomsShow,
} from "pages/real-estate/rooms";
import {
  RealEstateBuildingsCreate,
  RealEstateBuildingsEdit,
  RealEstateBuildingsList,
  RealEstateBuildingsShow,
} from "pages/real-estate/buildings";
import {
  ReservationsCreate,
  ReservationsEdit,
  ReservationsList,
  ReservationsShow,
} from "pages/operation/reservations";
import {
  LandlordBillsCreate,
  LandlordBillsEdit,
  LandlordBillsList,
  LandlordBillsShow,
} from "pages/payment/bills";
import {
  LandlordTransactionsCreate,
  LandlordTransactionsEdit,
  LandlordTransactionsList,
  LandlordTransactionsShow,
} from "pages/payment/landlord-transactions";

const axiosInstance: any = axios.create();
axiosInstance.interceptors.request.use((request: AxiosRequestConfig): any => {
  // Retrieve the token from local storage
  const token = localStorage.getItem("token");
  // Check if the header property exists
  if (request.headers) {
    // Set the Authorization header if it exists
    request.headers["Authorization"] = `Bearer ${token}`;
  } else {
    // Create the headers property if it does not exist
    request.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return request;
});

const App: React.FC = () => {
  const { t, i18n } = useTranslation();
  const i18nProvider = {
    translate: (key: string, params?: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <ColorModeContextProvider>
      <BrowserRouter>
        <ConfigProvider
          theme={{
            ...RefineThemes.Blue,
            components: {
              Button: {
                borderRadius: 0,
                colorBorder: "#423f3c",
                colorPrimary: "#423f3c",
              },
              Dropdown: {
                colorPrimary: "#423f3c",
              },
              Menu: {
                colorPrimary: "#423f3c",
              },
              Typography: {
                colorTextHeading: "#423f3c",
              },
              Slider: {
                dotBorderColor: "#020bf7",
                trackBg: "#91caff",
                trackHoverBg: "#91caff",
                handleActiveColor: "#91caff",
                handleColor: "#91caff",
                handleSizeHover: 10,
              },
            },
            token: {
              colorPrimary: "#e7dcd4",
            },
          }}
        >
          <Refine
            dataProvider={dataProvider(API_URL, axiosInstance)}
            authProvider={authProvider}
            routerProvider={routerProvider}
            i18nProvider={i18nProvider}
            resources={[
              {
                name: "dashboard",
                list: "/",
                meta: {
                  role: ["admin", "sale", "landlord"],
                  label: t("pages.dashboard.title"),
                  icon: <DashboardOutlined />,
                },
              },
              {
                name: "cart-management",
                meta: {
                  role: ["admin", "data-admin", "team-leader", "sale"],
                  icon: <ShoppingCartOutlined />,
                  label: t("pages.cartManagement.title"),
                },
              },
              {
                name: "real-estate",
                meta: {
                  role: ["landlord"],
                  icon: <PartitionOutlined />,
                  label: t("pages.realEstate.title"),
                },
              },
              {
                name: "operation",
                meta: {
                  role: ["landlord"],
                  icon: <ClusterOutlined />,
                  label: t("pages.operation.title"),
                },
              },
              {
                name: "payment",
                meta: {
                  role: ["landlord"],
                  icon: <MoneyCollectOutlined />,
                  label: t("pages.payment.title"),
                },
              },
              {
                name: "team-management",
                meta: {
                  role: ["team-leader"], // this just only team-leader access
                  icon: <ApartmentOutlined />,
                  label: t("pages.teamsManagement.title"),
                },
              },
              {
                name: "teams",
                list: "/teams",
                create: "/teams/create",
                edit: "/teams/edit/:id",
                show: "/teams/show/:id",
                meta: {
                  role: ["admin"], // this just only admin access
                  label: t("pages.teamsManagement.title"),
                  icon: <ApartmentOutlined />,
                },
              },
              // team managemement
              // specific only team-leader access
              {
                name: "teams-management",
                list: "/teams-management",
                create: "/teams-management/create",
                edit: "/teams-management/edit/:id",
                show: "/teams-management/show/:id",
                meta: {
                  role: ["admin", "data-admin",],
                  parent: "team-management",
                  label: t("pages.teamsManagement.list"),
                  icon: <ApartmentOutlined />,
                },
              },
              {
                name: "appointments-teams",
                list: "/appointments-teams",
                create: "/appointments-teams/create",
                edit: "/appointments-teams/edit/:id",
                show: "/appointments-teams/show/:id",
                meta: {
                  role: ["team-leader"],
                  parent: "team-management",
                  label: t("pages.customerManagement.appointments.title"),
                  icon: <HomeOutlined />,
                },
              },
              {
                name: "deal-teams",
                list: "/deal-teams",
                edit: "/deal-teams/edit/:id",
                show: "/deal-teams/show/:id",
                meta: {
                  role: [],
                  parent: "team-management",
                  label: t("pages.financialManagement.deals.title"),
                  icon: <ReconciliationOutlined />,
                },
              },
              // end team management

              // landlord management for real estate
              // specific only landlord access
              {
                name: "rooms",
                list: "/rooms",
                create: "/rooms/create",
                edit: "/rooms/edit/:id",
                show: "/rooms/show/:id",
                meta: {
                  role: ["landlord"], // this just only landlord access
                  parent: "real-estate",
                  label: t("pages.realEstate.rooms.title"),
                  icon: <HomeOutlined />,
                },
              },
              {
                name: "real-estate-buildings",
                list: "/real-estate-buildings",
                create: "/real-estate-buildings/create",
                edit: "/real-estate-buildings/edit/:id",
                show: "/real-estate-buildings/show/:id",
                meta: {
                  role: ["landlord"], // this just only landlord access
                  parent: "real-estate",
                  label: t("pages.realEstate.buildings.title"),
                  icon: <InsertRowLeftOutlined />,
                },
              },

              {
                name: "reservations",
                list: "/reservations",
                create: "/reservations/create",
                edit: "/reservations/edit/:id",
                show: "/reservations/show/:id",
                meta: {
                  role: ["landlord"], // this just only landlord access
                  parent: "operation",
                  label: t("pages.operation.reservations.title"),
                  icon: <PhoneOutlined />,
                },
              },
              {
                name: "customers",
                list: "/customers",
                create: "/customers/create",
                edit: "/customers/edit/:id",
                show: "/customers/show/:id",
                meta: {
                  role: ["landlord"], // this just only landlord access
                  parent: "operation",
                  label: t("pages.customerManagement.customers.title"),
                  icon: <ContactsOutlined />,
                },
              },
              {
                name: "contracts",
                list: "/contracts",
                create: "/contracts/create",
                edit: "/contracts/edit/:id",
                show: "/contracts/show/:id",
                meta: {
                  role: ["landlord"],
                  parent: "payment",
                  label: t("pages.payment.contracts.title"),
                  icon: <ContainerOutlined />,
                },
              },
              {
                name: "bills",
                list: "/bills",
                create: "/bills/create",
                edit: "/bills/edit/:id",
                show: "/bills/show/:id",
                meta: {
                  role: ["landlord"],
                  parent: "payment",
                  label: t("pages.payment.landlordBills.title"),
                  icon: <CopyOutlined />,
                },
              },
              {
                name: "landlord-transactions",
                list: "/landlord-transactions",
                create: "/landlord-transactions/create",
                edit: "/landlord-transactions/edit/:id",
                show: "/landlord-transactions/show/:id",
                meta: {
                  role: ["landlord"],
                  parent: "payment",
                  label: t("pages.payment.landlordTransactions.title"),
                  icon: <DollarOutlined />,
                },
              },

              // end landlord

              {
                name: "apartments",
                list: "/apartments",
                create: "/apartments/create",
                edit: "/apartments/edit/:id",
                show: "/apartments/show/:id",
                meta: {
                  role: ["admin", "data-admin", "team-leader", "sale"],
                  parent: "cart-management",
                  label: t("pages.cartManagement.apartments.title"),
                  icon: <HomeOutlined />,
                },
              },
              {
                name: "customer-management",
                meta: {
                  role: [
                    "admin",
                    "data-admin",
                    "team-leader",
                    "finance",
                    "sale",
                  ],
                  icon: <UserSwitchOutlined />,
                  label: t("pages.customerManagement.title"),
                },
              },
              {
                name: "appointments",
                list: "/appointments",
                create: "/appointments/create",
                edit: "/appointments/edit/:id",
                show: "/appointments/show/:id",
                meta: {
                  role: [
                    "admin",
                    "data-admin",
                    "team-leader",
                    "finance",
                    "sale",
                  ],
                  parent: "customer-management",
                  label: t("pages.customerManagement.appointments.title"),
                  icon: <ScheduleOutlined />,
                },
              },
              {
                name: "customers",
                list: "/customers",
                create: "/customers/create",
                edit: "/customers/edit/:id",
                show: "/customers/show/:id",
                meta: {
                  role: ["admin", "data-admin", "team-leader", "sale"],
                  parent: "customer-management",
                  label: t("pages.customerManagement.customers.title"),
                  icon: <ContactsOutlined />,
                },
              },
              {
                name: "buildings",
                list: "/buildings",
                create: "/buildings/create",
                edit: "/buildings/edit/:id",
                show: "/buildings/show/:id",
                meta: {
                  role: ["admin", "data-admin", "team-leader", "sale"],
                  parent: "cart-management",
                  label: t("pages.cartManagement.buildings.title"),
                  icon: <BuildOutlined />,
                },
              },
              {
                name: "buildings-apartments",
                list: "/buildings-apartments",
                edit: "/buildings-apartments/edit/:id",
                meta: {
                  role: [
                    "admin",
                    "data-admin",
                    "team-leader",
                    "sale",
                    "landlord",
                    "customer",
                  ],
                  parent: "buildings",
                  label: t("pages.cartManagement.apartments.listAll"),
                  icon: <HomeOutlined />,
                  hide: true,
                },
              },
              {
                name: "landlords",
                list: "/landlords",
                create: "/landlords/create",
                edit: "/landlords/edit/:id",
                show: "/landlords/show/:id",
                meta: {
                  role: ["admin", "data-admin"],
                  parent: "cart-management",
                  label: t("pages.cartManagement.landlords.title"),
                  icon: <IdcardOutlined />,
                },
              },
              {
                name: "sale-rights-management",
                meta: {
                  role: ["admin", "data-admin"],
                  parent: "cart-management",
                  label: t("pages.cartManagement.saleRightsManagement.title"),
                  icon: <PicRightOutlined />,
                },
              },
              {
                name: "area",
                list: "/area",
                create: "/area/create",
                edit: "/area/edit/:id",
                show: "/area/show/:id",
                meta: {
                  role: ["admin"], // this just only admin access
                  parent: "cart-management",
                  label: t("pages.cartManagement.areasManagement.title"),
                  icon: <ApartmentOutlined />,
                },
              },
              {
                name: "building-groups",
                list: "/building-groups",
                create: "/building-groups/create",
                edit: "/building-groups/edit/:id",
                show: "/building-groups/show/:id",
                meta: {
                  role: ["admin", "data-admin"],
                  parent: "sale-rights-management",
                  label: t(
                    "pages.cartManagement.saleRightsManagement.buildingGroups.title"
                  ),
                  icon: <GroupOutlined />,
                },
              },
              {
                name: "sale-groups",
                list: "/sale-groups",
                create: "/sale-groups/create",
                edit: "/sale-groups/edit/:id",
                show: "/sale-groups/show/:id",
                meta: {
                  role: ["admin", "data-admin"],
                  parent: "sale-rights-management",
                  label: t(
                    "pages.cartManagement.saleRightsManagement.saleGroups.title"
                  ),
                  icon: <DeploymentUnitOutlined />,
                },
              },
              {
                name: "sale-rights",
                list: "/sale-rights",
                create: "/sale-rights/create",
                edit: "/sale-rights/edit/:id",
                show: "/sale-rights/show/:id",
                meta: {
                  role: ["admin", "data-admin"],
                  parent: "sale-rights-management",
                  label: t(
                    "pages.cartManagement.saleRightsManagement.saleRights.title"
                  ),
                  icon: <DingdingOutlined />,
                },
              },
              {
                name: "financial-management",
                meta: {
                  role: [
                    "admin",
                    "data-admin",
                    "finance",
                    "sale",
                    "team-leader",
                  ],
                  icon: <DollarOutlined />,
                  label: t("pages.financialManagement.title"),
                },
              },
              // {
              //   name: "bills",
              //   list: "/bills",
              //   create: "/bills/create",
              //   edit: "/bills/edit/:id",
              //   show: "/bills/show/:id",
              //   meta: {
              //     role: ["admin", "data-admin", "landlord"],
              //     parent: "financial-management",
              //     label: t("pages.financialManagement.bills.title"),
              //     icon: <FileTextOutlined />,
              //   },
              // },
              {
                name: "transactions",
                list: "/transactions",
                edit: "/transactions/edit/:id",
                show: "/transactions/show/:id",
                meta: {
                  role: ["admin", "data-admin", "finance"],
                  parent: "financial-management",
                  label: t("pages.financialManagement.transactions.title"),
                  icon: <TransactionOutlined />,
                },
              },
              {
                name: "commissions",
                list: "/commissions",
                edit: "/commissions/edit/:id",
                show: "/commissions/show/:id",
                meta: {
                  role: ["admin", "finance"],
                  parent: "financial-management",
                  label: t("pages.financialManagement.commissions.title"),
                  icon: <DollarOutlined />,
                },
              },
              {
                name: "deals",
                list: "/deals",
                edit: "/deals/edit/:id",
                show: "/deals/show/:id",
                meta: {
                  role: [
                    "admin",
                    "data-admin",
                    "team-leader",
                    "finance",
                    "sale",
                  ],
                  parent: "financial-management",
                  label: t("pages.financialManagement.deals.title"),
                  icon: <ReconciliationOutlined />,
                },
              },
              {
                name: "users",
                list: "/users",
                create: "/users/create",
                edit: "/users/edit/:id",
                show: "/users/show/:id",
                meta: {
                  role: ["admin"],
                  label: t("pages.users.title"),
                  icon: <UserOutlined />,
                },
              },
            ]}
            notificationProvider={useNotificationProvider}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
              reactQuery: {
                devtoolConfig: false,
              },
            }}
          >
            <Routes>
              <Route
                element={
                  <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                    <ThemedLayoutV2 Sider={CustomSider} Header={Header}>
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route index element={<Dashboard />} />

                {/* cart management */}
                <Route path="apartments">
                  <Route index element={<ApartmentsList />} />
                  <Route path="create" element={<ApartmentsCreate />} />
                  <Route path="edit/:id" element={<ApartmentsEdit />} />
                  <Route path="show/:id" element={<ApartmentsShow />} />
                </Route>

                <Route path="buildings">
                  <Route index element={<BuildingsList />} />
                  <Route path="create" element={<BuildingsCreate />} />
                  <Route path="edit/:id" element={<BuildingsEdit />} />
                  <Route path="show/:id" element={<BuildingsShow />} />
                </Route>

                <Route path="buildings-apartments">
                  <Route index element={<BuildingsApartmentsList />} />
                  <Route
                    path="edit/:id"
                    element={<BuildingsApartmentsEdit />}
                  />
                </Route>

                <Route path="landlords">
                  <Route index element={<LandlordsList />} />
                  <Route path="create" element={<LandlordsCreate />} />
                  <Route path="edit/:id" element={<LandlordsEdit />} />
                  <Route path="show/:id" element={<LandlordsShow />} />
                </Route>

                <Route path="building-groups">
                  <Route index element={<BuildingGroupsList />} />
                  <Route path="create" element={<BuildingGroupsCreate />} />
                  <Route path="edit/:id" element={<BuildingGroupsEdit />} />
                  <Route path="show/:id" element={<BuildingGroupsShow />} />
                </Route>

                <Route path="sale-groups">
                  <Route index element={<SaleGroupsList />} />
                  <Route path="create" element={<SaleGroupsCreate />} />
                  <Route path="edit/:id" element={<SaleGroupsEdit />} />
                  <Route path="show/:id" element={<SaleGroupsShow />} />
                </Route>

                <Route path="sale-rights">
                  <Route index element={<SaleRightsList />} />
                  <Route path="create" element={<SaleRightsCreate />} />
                  <Route path="edit/:id" element={<SaleRightsEdit />} />
                  <Route path="show/:id" element={<SaleRightsShow />} />
                </Route>
                <Route path="area">
                  <Route index element={<AreasList />} />
                  <Route path="create" element={<AreasCreate />} />
                  <Route path="edit/:id" element={<AreasEdit />} />
                  <Route path="show/:id" element={<AreasShow />} />
                </Route>
                {/* end cart management */}

                {/* teams management admin */}
                <Route path="teams">
                  <Route index element={<TeamsList />} />
                  <Route path="create" element={<TeamsCreate />} />
                  <Route path="edit/:id" element={<TeamsEdit />} />
                  <Route path="show/:id" element={<TeamsShow />} />
                </Route>
                {/* end teams management admin */}

                {/* real estate management for landlord */}
                <Route path="rooms">
                  <Route index element={<RoomsList />} />
                  <Route path="create" element={<RoomsCreate />} />
                  <Route path="edit/:id" element={<RoomsEdit />} />
                  <Route path="show/:id" element={<RoomsShow />} />
                </Route>

                <Route path="real-estate-buildings">
                  <Route index element={<RealEstateBuildingsList />} />
                  <Route
                    path="create"
                    element={<RealEstateBuildingsCreate />}
                  />
                  <Route
                    path="edit/:id"
                    element={<RealEstateBuildingsEdit />}
                  />
                  <Route
                    path="show/:id"
                    element={<RealEstateBuildingsShow />}
                  />
                </Route>
                {/* end real estate management for landlord  */}

                {/* real estate management for landlord */}
                <Route path="reservations">
                  <Route index element={<ReservationsList />} />
                  <Route path="create" element={<ReservationsCreate />} />
                  <Route path="edit/:id" element={<ReservationsEdit />} />
                  <Route path="show/:id" element={<ReservationsShow />} />
                </Route>
                <Route path="contracts">
                  <Route index element={<ContractsList />} />
                  <Route path="create" element={<ContractsCreate />} />
                  <Route path="edit/:id" element={<ContractsEdit />} />
                  <Route path="show/:id" element={<ContractsShow />} />
                </Route>

                <Route path="bills">
                  <Route index element={<LandlordBillsList />} />
                  <Route path="create" element={<LandlordBillsCreate />} />
                  <Route path="edit/:id" element={<LandlordBillsEdit />} />
                  <Route path="show/:id" element={<LandlordBillsShow />} />
                </Route>

                <Route path="landlord-transactions">
                  <Route index element={<LandlordTransactionsList />} />
                  <Route
                    path="create"
                    element={<LandlordTransactionsCreate />}
                  />
                  <Route
                    path="edit/:id"
                    element={<LandlordTransactionsEdit />}
                  />
                  <Route
                    path="show/:id"
                    element={<LandlordTransactionsShow />}
                  />
                </Route>

                {/* teams management team-leader */}
                {/* <Route path="teams-management">
                  <Route index element={<TeamsManagementList />} />
                  <Route path="create" element={<TeamsManagementCreate />} />
                  <Route path="edit/:id" element={<TeamsManagementEdit />} />
                  <Route path="show/:id" element={<TeamsManagementShow />} />
                </Route> */}
                <Route path="appointments-teams">
                  <Route index element={<AppointmentsTeamList />} />
                  <Route path="create" element={<AppointmentsTeamCreate />} />
                  <Route path="edit/:id" element={<AppointmentsTeamEdit />} />
                  <Route path="show/:id" element={<AppointmentsTeamShow />} />
                </Route>
                {/* <Route path="deal-teams">
                  <Route index element={<DealsTeamList />} />
                  <Route path="edit/:id" element={<DealsTeamEdit />} />
                  <Route path="show/:id" element={<DealsTeamShow />} />
                </Route> */}
                {/* end teams management team-leader */}

                {/* customers management */}
                <Route path="appointments">
                  <Route index element={<AppointmentsList />} />
                  <Route path="create" element={<AppointmentsCreate />} />
                  <Route path="edit/:id" element={<AppointmentsEdit />} />
                  <Route path="show/:id" element={<AppointmentsShow />} />
                </Route>

                <Route path="customers">
                  <Route index element={<CustomersList />} />
                  <Route path="create" element={<CustomersCreate />} />
                  <Route path="edit/:id" element={<CustomersEdit />} />
                  <Route path="show/:id" element={<CustomersShow />} />
                </Route>
                {/* end customers management */}

                {/* financial management */}
                <Route path="bills">
                  <Route index element={<BillsList />} />
                  <Route path="create" element={<BillsCreate />} />
                  <Route path="edit/:id" element={<BillsEdit />} />
                  <Route path="show/:id" element={<BillsShow />} />
                </Route>

                <Route path="transactions">
                  <Route index element={<TransactionList />} />
                  <Route path="edit/:id" element={<TransactionEdit />} />
                  <Route path="show/:id" element={<TransactionShow />} />
                </Route>

                <Route path="commissions">
                  <Route index element={<CommissionList />} />
                  <Route path="edit/:id" element={<CommissionEdit />} />
                  <Route path="show/:id" element={<CommissionShow />} />
                </Route>
                <Route path="deals">
                  <Route index element={<DealsList />} />
                  <Route path="edit/:id" element={<DealsEdit />} />
                  <Route path="show/:id" element={<DealsShow />} />
                </Route>
                {/* end financial management */}

                <Route path="users">
                  <Route index element={<UserList />} />
                  <Route path="create" element={<UserCreate />} />
                  <Route path="edit/:id" element={<UserEdit />} />
                  <Route path="show/:id" element={<UserShow />} />
                </Route>
              </Route>

              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <NavigateToResource resource="users" />
                  </Authenticated>
                }
              >
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
              </Route>

              <Route
                element={
                  <Authenticated>
                    <ThemedLayoutV2 Sider={CustomSider} Header={Header}>
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route path="*" element={<ErrorComponent />} />
              </Route>
            </Routes>
            <UnsavedChangesNotifier />
          </Refine>
        </ConfigProvider>
      </BrowserRouter>
    </ColorModeContextProvider>
  );
};

export default App;
